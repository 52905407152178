export function hasChildren(item) {
    const { items: children } = item;

    if (children === undefined) {
        return false;
    }

    if (children.constructor !== Array) {
        return false;
    }

    if (children.length === 0) {
        return false;
    }

    return true;
}

export function replaceNullValues(obj) {
    return JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)))
}

export function parseErrors(response) {
    const { error } = response.data

    if (response?.status === 400) {
        const { errors } = error

        const tmp = Object.keys(errors).map(k => {
            return {
                field: k,
                message: errors[k][0]
            }
        })

        return tmp
    }

    if (response?.status === 401) {
        const { error } = response.data
        return error.message
    }
}

export function getFormatedDate(date) {
    let day = date.getDate()
    day = day < 10 ? `0${day}` : day
    let month = date.getMonth() + 1
    month = month < 10 ? `0${month}` : month
    const year = date.getFullYear()

    return `${year}-${month}-${day}`
}


/**
 * Sum currencies so that if there are two different currencies,
 * discard currencies with value 0, if both are 0 return 0 with the first currency
 * show as a sum sorted by the currency,
 * and if there is one sum the two values into one
 * Example 1: 1 USD + 1 USD = 2 USD
 * Example 2: 1 USD + 1 EUR = 1 USD + 1 EUR
 */
export function sumCurrencies(amount1, currency1, amount2, currency2) {
    if (currency1 === currency2) {
        return [{
            amount: amount1 + amount2,
            currency: currency1
        }]
    } else {
        const currencies = [{
            amount: amount1,
            currency: currency1
        }, {
            amount: amount2,
            currency: currency2
        }]

        const processedCurrencies = currencies.sort((a, b) => a.currency.localeCompare(b.currency)).filter((currency) => currency.amount !== 0)
        return processedCurrencies.length === 0 ? [{
            amount: 0,
            currency: currency1
        }] : processedCurrencies
    }
}
