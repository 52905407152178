import { Grid } from '@mui/material'
import LastTransactions from './LastTransactions'
import Pie from './Pie'
import Sales from './Sales'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useIsMounted from '../../../hooks/useIsMounted'
import AlertDialog from '../../Misc/AlertDialog'


export default function Balance() {
    const { t } = useTranslation()

    const { setAuth } = useAuth()

    const [showDialog, setShowDialog] = useState(false)

    const isMounted = useIsMounted()

    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = useState({
        _sales: true,
        _pie: true,
        _last: true
    })

    const topTransactions = 10
    const [days] = useState(60)

    const [sales, setSales] = useState([])
    const [pieData, setPieData] = useState([])
    const [last, setLast] = useState([])

    useEffect(() => {
        const retrieveData = () => {
            axios.all([
                axiosPrivate.get('/merchant/balance/sales/days/', { params: { days: days } }),
                axiosPrivate.get('/merchant/balance/sales/status/', { params: { days: days } }),
                axiosPrivate.get('/merchant/transactions/', {
                    params: {
                        status: 2,
                        limit: topTransactions
                    }
                })

            ])
                .then(axios.spread((...responses) => {
                    if (isMounted()) {
                        const requestByDay = responses[0]
                        const requestByStatus = responses[1]
                        const requestTransactions = responses[2]

                        setSales(requestByDay.data.data)
                        setPieData(requestByStatus.data.data)
                        setLast(requestTransactions.data.data.slice(0, topTransactions))
                    }
                }))
                .catch(({ response }) => {
                    if (response?.status === 401) {
                        localStorage.removeItem('user')
                        return setAuth({})
                    }

                    toast.error(t('Something went wrong'))
                })
                .finally(() => {
                    setLoading((l) => ({ ...l, _sales: false }))
                    setLoading((l) => ({ ...l, _pie: false }))
                    setLoading((l) => ({ ...l, _last: false }))
                })
        }

        retrieveData()
    }, [days, setAuth, axiosPrivate, t, isMounted])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Sales data={sales} days={days} loading={loading._sales} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Pie data={pieData} days={days} loading={loading._pie} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LastTransactions data={last} loading={loading._last} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <AlertDialog isOpen={showDialog} setOpen={setShowDialog} />
        </>
    )
}

