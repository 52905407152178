const languages = {
    "ES": "Español",
    "EN": "English",
    "PT": "Português",
}

const STATUS_ACTIVE = 1
const STATUS_TERMINATED = 3

export {
    languages,
    STATUS_ACTIVE,
    STATUS_TERMINATED
}