import { Grid, Box, Typography, TextField, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Copyright from './Copyright'
import Button from '@mui/material/Button'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../api'
import { useState } from 'react'
import { toast } from 'react-toastify';


const SignInButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '2px solid',
    lineHeight: 1.5,
    backgroundColor: '#09097d',
    color: 'white',
    borderColor: 'white',
    '&:hover': {
        backgroundColor: '#0B1F3F',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        borderColor: '#005cbf',
    }
})

const TextButton = styled(Button)({
    textTransform: 'none'
})

export default function PasswordForm() {
    const { t } = useTranslation()

    const [authenticating, setAuthenticating] = useState(false)

    const navigate = useNavigate()

    const handleSubmit = (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        setAuthenticating(true)

        api.post('/auth/password/reset/', {
            email: data.get('email')
        }, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then(() => {
                toast.success(t('An email has been sent with instructions to reset your password'))
                navigate('/auth/login')
            })
            .catch(({ response }) => {
                toast.error(t('Something went wrong'))
            })
            .finally(() => setAuthenticating(false))
    }

    return (
        <>
            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Stack sx={{ m: 1 }}>
                    <img src='https://cdn.paygol.com/images/v2/paygol-isotipo-50x50.png' alt="Paygol" />
                </Stack>

                <Typography component="h1" variant="h5">
                    {t('Recover your password')}
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label={t('Email address')}
                        name="email"
                        autoComplete="off"
                        autoFocus
                    />

                    <SignInButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={authenticating}
                    >
                        {t('Send')}
                    </SignInButton>
                    <Grid container>
                        <Grid item xs align="center">
                            <TextButton to="/auth/login" component={Link}>
                                {t('Already have an account? Sign in')}
                            </TextButton>
                        </Grid>
                    </Grid>
                    <Copyright sx={{ mt: 5 }} />
                </Box>
            </Box>
        </>
    )
}
