import { useState, useEffect, useCallback } from 'react'
import { Hidden } from '@mui/material'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useAuth from '../../../hooks/useAuth'
import useIsMounted from '../../../hooks/useIsMounted';
import { STATUS_ACTIVE, STATUS_TERMINATED } from '../../../enums/Merchant'
import WithdrawalsMobile from './WithdrawalsMobile'
import WithdrawalsDesktop from './WithdrawalsDesktop'

export default function WithdrawalsList() {
    const { t } = useTranslation()

    const { auth, setAuth } = useAuth()

    const axiosPrivate = useAxiosPrivate()

    const isMounted = useIsMounted()

    const [loading, setLoading] = useState(true)

    const [requesting, setRequesting] = useState(false)

    const [currency, setCurrency] = useState('USD')

    const [country, setCountry] = useState('')

    const [methods, setMethods] = useState([])

    const [summary, setSummary] = useState([])

    const [minimum, setMinimum] = useState(0)

    const [allowWithdrawal, setAllowWithdrawal] = useState(0)

    const [pending, setPending] = useState(null)

    const [open, setOpen] = useState(false)

    const retrieveData = useCallback(() => {
        axios.all([
            axiosPrivate.get('/merchant/withdrawals/pending/'),
            axiosPrivate.get('/merchant/balance/')
        ]).then(axios.spread((...responses) => {
            if (isMounted()) {
                const requestPending = responses[0]
                const { data } = responses[1]

                const { methods, summary, country, currency, minimum_payout_amount } = data.data

                setPending(requestPending.data.data)

                setMethods(methods)
                setSummary(summary)
                setCurrency(currency)
                setCountry(country)
                setMinimum(minimum_payout_amount)
                setAllowWithdrawal(
                    minimum_payout_amount < summary.withdrawable_amount &&
                    (auth.user.merchant_status === STATUS_ACTIVE || auth.user.merchant_status === STATUS_TERMINATED)
                )
            }
        }))
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))
        // eslint-disable-next-line
    }, [t, axiosPrivate, setAuth, isMounted])

    const handleRequest = () => {
        setRequesting(true)
        return axiosPrivate.post('/merchant/withdrawals/', {})
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    setMethods(methods.map(m => {
                        m.total = m.total - m.available
                        m.available = 0
                        return m
                    }))
                    setSummary([])
                    setPending(data)
                    setAllowWithdrawal(false)
                    toast.success(t('Your request has been submitted'))
                }
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
            .finally(() => setRequesting(false))
    }

    useEffect(() => {
        const loadData = () => {
            setLoading(true)
            retrieveData()
        }

        loadData()
    }, [retrieveData])

    return (
        <>
            <Hidden mdUp={true}>
                <WithdrawalsMobile
                    methods={methods}
                    summary={summary}
                    amount={pending ? pending.withdrawable_amount : 0}
                    currency={currency}
                    allowWithdrawal={allowWithdrawal && !pending}
                    loading={loading}
                    requesting={requesting}
                    min_amount={new Intl.NumberFormat('es-CL', { style: 'currency', currency: currency }).format(minimum)}
                    handleRequest={handleRequest}
                    open={open}
                    setOpen={setOpen}
                    days={country && country === 'CL' ? 2 : 7}
                    pending={pending}
                />
            </Hidden>

            <Hidden mdDown={true}>
                <WithdrawalsDesktop
                    methods={methods}
                    summary={summary}
                    amount={pending ? pending.withdrawable_amount : 0}
                    currency={currency}
                    allowWithdrawal={allowWithdrawal && !pending}
                    loading={loading}
                    requesting={requesting}
                    min_amount={new Intl.NumberFormat('es-CL', { style: 'currency', currency: currency }).format(minimum)}
                    handleRequest={handleRequest}
                    open={open}
                    setOpen={setOpen}
                    days={country && country === 'CL' ? 2 : 7}
                    pending={pending}
                />
            </Hidden>
        </>
    )
}
