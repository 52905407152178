import { Card, CardContent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'


export default function Faq() {
    const { t } = useTranslation()

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h4" mt={2} mb={4}>{t('FAQ')}</Typography>
                </CardContent>
            </Card>
        </>
    )
}
