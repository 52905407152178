import { Card, CardContent, Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import ChartHeader from './ChartHeader'
import useAuth from '../../../hooks/useAuth';
import { useEffect, useState } from 'react'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default function Sales({
    data,
    days,
    loading
}) {
    const { t } = useTranslation()

    const { auth } = useAuth()

    const [currency, setCurrency] = useState('')

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
            },
        },
    }

    const labels = data.map(d => new Intl.DateTimeFormat(['ban', 'id']).format(new Date(d.paid_at)))

    const dataset = data.map(d => parseInt(d.sum))

    const _data = {
        labels,
        datasets: [
            {
                label: t('Turnover'),
                data: dataset,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    }

    useEffect(() => {
        if (auth?.user?.settlement_currency) {
            setCurrency(auth?.user?.settlement_currency)
        }

        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Card p={2}>
                <CardContent>
                    <ChartHeader
                        title={t('Sales last {{days}} days', { days: days })}
                        // subtitle={t('Amounts represented in account currency')}
                        subtitle={t('Amounts represented in {{currency}}', { currency: currency })}
                    />

                    {!loading ?
                        <Line
                            options={options}
                            data={_data}
                            width={'90%'}
                        /> :
                        <Box>
                            <Skeleton variant="text" animation="wave" height={50} />
                            <Skeleton variant="rectangular" width='100%' height={300} />
                        </Box>
                    }
                </CardContent>
            </Card>
        </>
    )
}
