import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'
import TableBackdrop from '../../TableUI/TableBackdrop'
import TableActions from '../../TableUI/TableActions'
import { Stack, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import IconButton from '@mui/material/IconButton'
import ChipWrapper from '../../TableUI/ChipWrapper'
import MethodCommission from './MethodCommission'
import { ST_REQUESTED } from './../../../enums/PaymentMethods'
import { useState } from 'react'
import { sumCurrencies } from '../../../services/utils'


/**
 * Show a popover with a table of sub methods and its fees
 */
function SubMethodsPopover({ subMethods, anchorEl, handleClose }) {
    const { t } = useTranslation()

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Submethod')}</TableCell>
                            <TableCell>{t('Fee delta')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subMethods.map((subMethod) => (
                            <TableRow key={subMethod.id}>
                                <TableCell>{subMethod.name}</TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing="0.3rem" alignItems="center">
                                        <MethodCommission
                                            fixed={subMethod.fullFixed[0].amount}
                                            fixed_currency={subMethod.fullFixed[0].currency}
                                            percent={subMethod.fullFixed.length === 1 ? subMethod.fee_percent : null}
                                        />
                                        {subMethod.fullFixed.length > 1 ? <>
                                            <p>{"+"}</p>
                                            <MethodCommission
                                                fixed={subMethod.fullFixed[1].amount}
                                                fixed_currency={subMethod.fullFixed[1].currency}
                                                percent={subMethod.fee_percent}
                                            />
                                        </> : null}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Popover>
    )
}


/**
 * Show a button that opens a popover with the sub methods if subMethods is not empty
 */
function SubmethodsButton({ subMethods }) {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return subMethods.length !== 0 ? (
        <>
            <IconButton onClick={handleClick} size="small">
                <CreditCardIcon />
            </IconButton>
            <SubMethodsPopover
                subMethods={subMethods}
                anchorEl={anchorEl}
                handleClose={handleClose}
            />
        </>
    ) : null
}

/**
 * If no submethod show just the fees, if there are submethods show a button that opens a popover
 * and add the raw fees as a submethod with name "Other"
 */
function FeeDisplay({ fixed, fixedCurrecy, percent, subMethods }) {
    const { t } = useTranslation()
    const processedSubMethods = [
        { name: t('Other'), fullFixed: [{ amount: fixed, currency: fixedCurrecy }], fee_percent: percent },
        ...subMethods.map((subMethod) => ({
            ...subMethod,
            fullFixed: sumCurrencies(
                subMethod.fee_fixed,
                subMethod.fee_fixed_currency,
                fixed,
                fixedCurrecy
            ),
            fee_percent: subMethod.fee_percent + percent,
        })),
    ]


    if (subMethods.length === 0) {
        return (
            <MethodCommission
                fixed={fixed}
                fixed_currency={fixedCurrecy}
                percent={percent}
            />
        )
    } else {
        return <SubmethodsButton subMethods={processedSubMethods} />
    }
}


export default function MethodsTable({
    data,
    setData,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange,
    handleToggleStatus
}) {
    const { t } = useTranslation()

    return (
        <>
            <Paper sx={{
                width: {
                    xs: 'calc(100vw - 80px)',
                    sm: 'calc(100vw - 330px)'
                }, overflow: 'hidden'
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Method')}</TableCell>
                                <TableCell align="center">{t('Commission')}</TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell>{t('Countries')}</TableCell>
                                <TableCell align="left">{t('Status')}</TableCell>
                                <TableCell align="right">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length ? data.filter(r => r.status !== 3).map((row, idx) => (
                                <TableRow
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                        <Typography variant="subtitle2">
                                            {row.api_code}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FeeDisplay
                                            fixed={row.fee_fixed}
                                            fixedCurrecy={row.fee_fixed_currency}
                                            percent={row.fee_percent}
                                            subMethods={row.submethods}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                    </TableCell>
                                    <TableCell>
                                        <ChipWrapper values={row.countries} />
                                    </TableCell>
                                    <TableCell>{t(row.status_)}</TableCell>
                                    <TableCell align="right">
                                        {row.status !== ST_REQUESTED ? <TableActions actions={[
                                            {
                                                id: `toggle-${row.api_code}`,
                                                handleClick: () => {
                                                    return handleToggleStatus({
                                                        code: row.api_code,
                                                    })
                                                },
                                                icon: row.status ? <ClearIcon /> : <CheckIcon />,
                                                title: row.status ? t('Disable method') : t('Enable method')
                                            }
                                        ]} /> : ''}
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                }
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
