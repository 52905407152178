import { useState } from 'react'
import { Box } from '@mui/system'
import {
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Avatar
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import { AccountCircle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../hooks/useAuth'

export default function ProfileTab({ email }) {
    const { t } = useTranslation()

    const [anchorEl, setAnchorEl] = useState(null)

    const { setAuth } = useAuth()

    const navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleNavigate = (view) => {
        setAnchorEl(null)
        navigate(view)
    }

    const handleLogout = () => {
        localStorage.removeItem('user')
        setAuth({})

        navigate('/auth')
    }

    return (
        <Box>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    pt: 0
                }}
            >
                <MenuItem selected>
                    <Avatar sx={{
                        mr: 2,
                        background: 'white',
                        color: '#0B1F3F'
                    }} />
                    <ListItemText>{email}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => { handleNavigate('/profile') }}>
                    <Avatar sx={{
                        mr: 2,
                        background: '#0B1F3F'
                    }}><AccountCircleIcon /></Avatar>
                    <ListItemText>{t('Account information')}</ListItemText>
                </MenuItem>

                <MenuItem onClick={handleLogout}>
                    <Avatar sx={{
                        mr: 2,
                        background: 'red'
                    }}><LogoutIcon /></Avatar>
                    <ListItemText>{t('Logout')}</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    )
}
