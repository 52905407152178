import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import CurrencyValue from '../../TableUI/CurrencyValue'

export default function WithdrawalsTableRow({
  title,
  available = 0,
  total = 0,
  currency
}) {
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell align="right">
        {total > 0 && <CurrencyValue amount={total} currency={currency} />}
      </TableCell>
      <TableCell align="right">
        <CurrencyValue amount={available} currency={currency} />
      </TableCell>
    </TableRow>
  )
}
