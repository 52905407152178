import GroupIcon from '@mui/icons-material/Group'
import PaymentsIcon from '@mui/icons-material/Payments'
import BarChartIcon from '@mui/icons-material/BarChart'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import CodeIcon from '@mui/icons-material/Code'
import PaidIcon from '@mui/icons-material/Paid'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ROLE_ACCOUNTING, ROLE_ADMIN, ROLE_DEV, ROLE_OWNER, ROLE_RO } from './enums/roles'

export const menu = [
    {
        icon: <BarChartIcon />,
        title: "Balance",
        items: [],
        to: '/'
    },
    {
        icon: <PaidIcon />,
        title: "Your money",
        to: '/withdrawals',
        roles: [
            ROLE_OWNER, ROLE_ADMIN, ROLE_ACCOUNTING
        ]
    },
    {
        icon: <PaymentsIcon />,
        title: "Transactions",
        to: '/transactions',
        roles: [
            ROLE_ADMIN, ROLE_OWNER, ROLE_ACCOUNTING, ROLE_DEV, ROLE_RO
        ]
    },
    {
        icon: <GroupIcon />,
        title: "Users",
        to: '/users',
        roles: [
            ROLE_ADMIN, ROLE_OWNER, ROLE_RO
        ]
    },
    {
        icon: <PaymentsIcon />,
        title: "Payment methods",
        to: '/payment-methods',
        roles: [
            ROLE_ADMIN, ROLE_OWNER, ROLE_DEV, ROLE_RO
        ]
    },
    {
        icon: <AlternateEmailIcon />,
        title: "Payment links",
        to: '/payment-links',
        roles: [
            ROLE_ADMIN, ROLE_OWNER, ROLE_DEV, ROLE_RO
        ]
    },
    {
        icon: <CreditScoreIcon />,
        title: "Settlements",
        to: '/settlements',
        roles: [
            ROLE_ADMIN, ROLE_OWNER, ROLE_ACCOUNTING, ROLE_RO
        ]
    },
    {
        icon: <AttachMoneyIcon />,
        title: "Cashouts",
        items: [
            {
                title: "Balance",
                to: '/cashouts/balance'
            },
            {
                title: "Transactions",
                to: '/cashouts'
            }
        ],
        roles: [
            ROLE_ADMIN, ROLE_OWNER, ROLE_ACCOUNTING, ROLE_RO
        ]
    },
    {
        icon: <CodeIcon />,
        title: "Developers",
        items: [
            {
                title: "Credentials",
                to: '/developers/credentials'
            },
            {
                title: "Config",
                to: '/developers/config'
            },
            {
                title: "Documentation",
                to: '/developers/docs',
                target: '_blank'
            },
        ],
        roles: [
            ROLE_ADMIN, ROLE_OWNER, ROLE_DEV
        ]
    },
    // {
    //     icon: <ArticleIcon />,
    //     title: "Documents",
    //     items: [
    //         {
    //             title: "FAQ",
    //             to: '/documents/faq'
    //         },
    //         {
    //             title: "Legal",
    //             to: '/documents/legal'
    //         },
    //     ],
    // }
]