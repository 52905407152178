import { Suspense } from 'react';
import AppRouter from "./routers/AppRouter"
import { createTheme, ThemeProvider } from '@mui/material'
import PageSkeletonWrapper from './components/SkeletonUI/PageSkeletonWrapper/PageSkeletonWrapper'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { AuthProvider } from './context/AuthProvider';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0B1F3F'
    },
    secondary: {
      main: '#09097D'
    },
    text: {
      primary: '#0B1F3F',
      secondary: '#0B1F3F'
    },
    background: {
      default: '#f0f2f5'
    }
  },
  typography: {
    fontSize: 12
  }
});

theme.typography.h4 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
}

export default function App() {
  return (
    <AuthProvider>
      <Suspense fallback={<PageSkeletonWrapper />}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
        <ToastContainer />
      </Suspense>
    </AuthProvider>

  )
}
