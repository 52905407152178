import axios from "axios"

export const axiosPrivate = axios.create({
    timeout: 10000,
    baseURL: 'https://merchantnewapiqa.paygol.com/api/v2'
});

export default axiosPrivate;

export const axiosCashouts = axios.create({
    timeout: 10000,
    baseURL: 'https://cashouts.paygol.com/api/v3'
});