import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../FormUI/SubmitWrapper/SubmitWrapper"

import SelectWrapper from "../../FormUI/SelectWrapper/SelectWrapper"
import TextfieldWrapper from "../../FormUI/Textfield/TextfieldWrapper"
import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import { useState, useEffect, useCallback } from 'react'
import DateTimePickerWrapper from '../../FormUI/DateTimePickerWrapper/DateTimePickerWrapper'
import { status } from "../../../enums/Transactions"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import { getFormatedDate } from "../../../services/utils"

export default function Filters({
    handleOnSearch
}) {
    const { t } = useTranslation()

    const { setAuth } = useAuth()

    const axiosPrivate = useAxiosPrivate()

    const [methods, setMethods] = useState([])

    const getMethods = useCallback(() => {
        axiosPrivate.get('/merchant/payment-methods/')
            .then(({ data: { data } }) => {
                setMethods(data.reduce((o, m) => Object.assign(o, { [m.api_code]: m.name }), {}))
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }
            })
    }, [axiosPrivate, setAuth])

    const today = new Date()

    const initialFormState = {
        transaction_id: '',
        status: '2',
        payment_method__api_code: '',
        status_date__date__gte: '',
        status_date__date__lte: '',
        customer__email: ''
    }

    const min_date = () => {
        const min_date = new Date((new Date()).setDate(today.getDate() - 60))
        return getFormatedDate(min_date)
    }

    useEffect(() => {
        const loadData = () => {
            getMethods()
        }

        loadData()
    }, [getMethods])

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="transaction_id"
                            label={t('Transaction ID')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="customer__email"
                            label={t('Customer email')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={{
                                ...status, 'all': 'All'
                            }}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="payment_method__api_code"
                            label={t('Payment method')}
                            options={
                                { 'all': 'All', ...methods }
                            }
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <DateTimePickerWrapper
                            name='status_date__date__gte'
                            label={t('From date')}
                            size="small"
                            inputProps={{
                                min: min_date(),
                                max: getFormatedDate(today)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <DateTimePickerWrapper
                            name='status_date__date__lte'
                            label={t('To date')}
                            size="small"
                            inputProps={{
                                min: min_date(),
                                max: getFormatedDate(today)
                            }}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik >
    )
}
