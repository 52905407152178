import { Typography } from '@mui/material';

export default function PageTitle({ title }) {
  return (
    <Typography
      variant="h4"
      mb={4}
      sx={{
        mt: {
          xs: '0px',
          md: '1rem'
        }
      }}
    >{title}</Typography>
  )
}
