import React from 'react'
import { Avatar, Card, CardContent, Grid, ListItemAvatar, Typography } from '@mui/material'
import { List, Divider } from '@mui/material';
import { ListItem } from '@mui/material';
import { ListItemText } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import AvatarSkeleton from '../../SkeletonUI/AvatarSkeleton';
import { useTranslation } from 'react-i18next';
import ChartHeader from './ChartHeader';

export default function LastTransactions({
    data,
    loading
}) {
    const { t } = useTranslation()

    return (
        <>
            <Card>
                <CardContent>
                    <ChartHeader title={t('Last {{last}} transactions paid', { last: 10 })} />

                    {!loading ? <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {
                            data.map((d) => (
                                <React.Fragment key={d.transaction_id}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={d.payment_method.name}
                                                sx={{ bgcolor: deepOrange[500] }}
                                            >{d.payment_method.name.charAt(0)}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={d.transaction_id}
                                            secondary={
                                                <>
                                                    <Grid container spacing={2} component="span">
                                                        <Grid item sm={12} md={6} component="span">{d.payment_method.name}</Grid>
                                                        <Grid item sm={12} md={6} textAlign='right' component="span">
                                                            {new Intl.NumberFormat('es-CL', { style: 'currency', currency: d.currency }).format(d.price)}
                                                        </Grid>
                                                    </Grid>

                                                    <Typography
                                                        sx={{ display: 'block' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {`${d.customer.first_name} ${d.customer.last_name}`}
                                                    </Typography>

                                                    <Typography
                                                        sx={{ display: 'block' }}
                                                        component="span">{
                                                            `${d.customer.email}`
                                                        }</Typography>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </React.Fragment>
                            ))
                        }
                    </List> :
                        <>
                            <AvatarSkeleton />
                        </>
                    }
                </CardContent>
            </Card>
        </>
    )
}
