import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import TableBackdrop from '../../TableUI/TableBackdrop'
import WithdrawalsTableRow from './WithdrawalsTableRow'
import { Button, Typography } from '@mui/material'
import Confirmation from '../../Misc/Confirmation'
import Stack from '@mui/material/Stack'
import AlertMessage from '../../Misc/AlertMessage'


export default function WithdrawalsTable({
    methods,
    summary,
    amount,
    currency,
    handleRequest,
    allowWithdrawal = false,
    minimum = 0,
    loading = false,
    requesting = false,
    days = 7,
    pending
}) {
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)

    // const days = summary.country && summary.country === 'CL' ? 2 : 7

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        handleRequest()
            .then(() => setOpen(false))
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Method')} {days}</TableCell>
                            <TableCell align="right">{t('Estimated sales')}</TableCell>
                            <TableCell align="right">{t('Withdrawable')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {methods.length ? methods.filter(m => m.total > 0).map((row, idx) => (
                            <WithdrawalsTableRow
                                key={idx}
                                title={row.name}
                                total={row.total}
                                available={row.available}
                                currency={currency}
                            />
                        ))
                            : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}></TableCell>
                                }
                            </TableRow>
                        }

                        {
                            summary.settlement_cost > 0 && <WithdrawalsTableRow
                                title={t('Settlement cost')}
                                available={summary.settlement_cost * (-1)}
                                currency={currency}
                            />
                        }

                        {
                            summary.chargebacks_fee > 0 && <WithdrawalsTableRow
                                title={t('Chargebacks')}
                                available={summary.chargebacks_fee * (-1)}
                                currency={currency}
                            />
                        }

                        {
                            summary.refunds_fee > 0 && <WithdrawalsTableRow
                                title={t('Refunds')}
                                available={summary.refunds_fee * (-1)}
                                currency={currency}
                            />
                        }

                        {
                            summary.cashouts > 0 && <WithdrawalsTableRow
                                title={t('Cashouts')}
                                available={summary.cashouts * (-1)}
                                currency={currency}
                            />
                        }

                        {
                            summary.other_charges > 0 && <WithdrawalsTableRow
                                title={t('Other charges')}
                                available={summary.other_charges * (-1)}
                                currency={currency}
                            />
                        }

                        {
                            summary.other_credits > 0 && <WithdrawalsTableRow
                                title={t('Other credits')}
                                available={summary.other_credits}
                                currency={currency}
                            />
                        }

                    </TableBody>

                    <TableBody sx={{
                        fontSize: '100%'
                    }}>
                        <WithdrawalsTableRow
                            title={t('Total')}
                            total={summary.total_payments}
                            available={summary.withdrawable_amount}
                            currency={currency}
                        />
                    </TableBody>
                </Table>
            </TableContainer>

            {allowWithdrawal ? <>
                <Stack spacing={2} direction="row" justifyContent="flex-end" mt={4}>
                    <Button variant="outlined" onClick={() => setOpen(true)}>{t('Request payout')}</Button>
                </Stack>

                <Confirmation
                    open={open}
                    title={t('Funds withdrawal confirmation')}
                    legend={
                        <>
                            <Typography
                                variant='span'
                                sx={{
                                    display: 'block'
                                }}
                            >{
                                    t('Please confirm your withdrawal request for an amount of {{amount}}', { amount: new Intl.NumberFormat('es-CL', { style: 'currency', currency: currency }).format(summary.withdrawable_amount) })
                                }</Typography>
                            <Typography
                                variant='span'>{
                                    t('Remember that you will receive your money within a maximum period of {{days}} business days', { days: days })
                                }</Typography>
                        </>
                    }
                    handleConfirm={handleConfirm}
                    handleClose={handleClose}
                    requesting={requesting}
                /></> : (!loading && !pending ? <AlertMessage
                    type='info'
                    title={t('You are not authorized to request withdrawals')}
                    message={
                        <>
                            <Typography variant="body1">{t('Possible reasons:')}</Typography>
                            {minimum > 0 ? <Typography variant="body2">
                                - {t('You need a minimum withdrawable amount of {{amount}} to be able to request a payout', {
                                    amount: minimum
                                })}
                            </Typography> : ''}
                            <Typography variant="body2">
                                - {t('You have not completed your documentation')}
                            </Typography>
                            <Typography variant="body2">
                                - {t('You have a withdrawal in progress')}
                            </Typography>
                        </>

                    }
                /> : '')
            }
        </>
    )
}
