import { Typography } from '@mui/material'

export default function DatetimeValue({
    date,
    text = '',
    timezone = 'America/Santiago'
}) {
    return (
        <Typography noWrap>
            {text} {date && new Intl.DateTimeFormat('es-CL',
                {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                    timeZone: timezone
                }).format(new Date(date))}
        </Typography>
    )
}
