import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { menu } from "../../menu"
import { hasChildren } from "../../services/utils"
import { useState } from "react"

import { NavLink, useLocation } from "react-router-dom"
import i18n from 'i18next'
import useAuth from '../../hooks/useAuth'


export default function SideMenu() {
    const { auth } = useAuth()

    const roles = auth?.user?.roles

    const { pathname } = useLocation()

    return menu
        .filter(i => i.title !== 'Cashouts' || auth?.user?.cashouts_is_enabled)
        .map((item, key) => <MenuItem
            key={key}
            item={item}
            current={pathname}
            roles={roles}
        />);
}

const MenuItem = ({ item, current, roles = [] }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel
    const hasPermissions = !item?.roles || item?.roles?.includes(roles[0])

    return <Component item={item} current={current} hasPermissions={hasPermissions} />
};

// FIXME agregar target solo si es necesario
const SingleLevel = ({ item, current, hasPermissions = false }) => {
    // const regexp = new RegExp(item.to)
    const selected = item.to === current // (item.to !== '/' && regexp.test(current)) || (item.to === '/' && current === '/')

    return (
        hasPermissions ? <ListItem
            button
            component={NavLink}
            to={item.to}
            target={
                item.target ? item.target : ''
            }
            rel='noopener noreferrer'
            selected={selected}
        >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={i18n.t(item.title)} />
        </ListItem> : ''
    );
};

const MultiLevel = ({ item, current, hasPermissions = false }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen((prev) => !prev)
    }

    return (
        hasPermissions ? <>
            <ListItem
                button
                onClick={handleClick}
            >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={i18n.t(item.title)} />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children.map((child, key) => (
                        <MenuItem key={key} item={child} current={current} />
                    ))}
                </List>
            </Collapse>
        </> : ''
    );
};