import { useState, useEffect, useCallback } from 'react'
import { Grid } from '@mui/material'

import DescriptionIcon from '@mui/icons-material/Description'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AppFilters from '../../AppFilters'
import Filters from './Filters'
import TransactionsTable from './TransactionsTable'

import { toast } from 'react-toastify'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import LoadingButton from '@mui/lab/LoadingButton'
import PageTitle from '../../Misc/PageTitle'

export default function TransactionsList() {
    const { t } = useTranslation()

    const { setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const [fetchingData, setFetchingData] = useState(false)

    const [params, setParams] = useState({
        status: 2
    })

    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnSearch = values => {
        if (values === params) {
            return false
        }

        values = {
            ...values,
            status: String(values.status).replace('all', ''),
            payment_method__api_code: String(values.payment_method__api_code).replace('all', ''),
        }

        setLoading(true)
        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)

        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const handleExport = () => {
        setFetchingData(true)

        axiosPrivate.get('/merchant/transactions/export/', { params: params })
            .then(response => {
                const binaryData = []

                binaryData.push(response.data)

                let anchor = document.createElement('a')

                anchor.href = window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }))

                anchor.download = 'transactions.csv'

                anchor.click()
            })
            .catch(() => {
                toast.error(t('Something went wrong'))
            })
            .finally(() => setFetchingData(false))
    }

    const retrieveData = useCallback(() => {
        axiosPrivate.get('/merchant/transactions/', { params: params })
            .then(({ data: { data, meta } }) => {
                setMeta(meta)
                setData(data)
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))
    }, [params, t, setAuth, axiosPrivate])

    useEffect(() => {
        const loadData = () => {
            retrieveData()
        }

        loadData()
    }, [params, retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Transactions')} />

                    <Grid container spacing={2} mt={2} mb={2}>
                        <Grid item xs={12}>
                            <LoadingButton
                                loading={fetchingData}
                                onClick={() => handleExport()}
                                loadingPosition="start"
                                startIcon={<DescriptionIcon />}
                                size="small"
                                className="base__btn-action"
                            >
                                {t('Export')}
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                    <TransactionsTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                    />
                </CardContent>
            </Card>
        </>
    )
}
