import { Grid, IconButton, Stack } from "@mui/material"
import TextfieldWrapper from "../../FormUI/Textfield/TextfieldWrapper"
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from "react-i18next"
import AddIcon from '@mui/icons-material/Add'
import { useEffect } from "react"


export default function PaymentLinksItem({
    idx, items, setTotal
}) {
    const { t } = useTranslation()

    useEffect(() => {
        setTotal(Object.values(items.form.values.items).map((item) => (item.quantity * item.unit_price)).reduce((a, b) => a + b))
    }, [items.form.values.items, setTotal])

    return (
        <>
            <Grid item xs={12} md={6} lg={6}>
                <TextfieldWrapper
                    name={`items[${idx}].item`}
                    label={t('Name')}
                    type="text"
                />
            </Grid>

            <Grid item xs={3} md={2}>
                <TextfieldWrapper
                    name={`items[${idx}].quantity`}
                    label={t('Quantity')}
                    type="number"
                />
            </Grid>

            <Grid item xs={5} md={3}>
                <TextfieldWrapper
                    name={`items[${idx}].unit_price`}
                    label={t('Price')}
                    type="number"
                />
            </Grid>

            <Grid item xs={2} md={1}>
                <Stack direction="row" mt={1} spacing={0}>
                    {idx !== 0 ? <IconButton
                        onClick={() => items.remove(idx)}
                        aria-label="Remove item">
                        <DeleteIcon />
                    </IconButton> : ''}
                    <IconButton
                        onClick={() => items.push({
                            item: '',
                            quantity: 1,
                            unit_price: ''
                        })}
                        aria-label="Add item">
                        <AddIcon />
                    </IconButton>
                </Stack>
            </Grid>
        </>
    )
}
