const TYPE_ADMIN = 3
const TYPE_DEV = 4
const TYPE_ACCOUNTING = 5
const TYPE_RO = 6

const ES = 'ES'
const EN = 'EN'
const PT = 'PT'

const types = {
    3: 'Administrator',
    4: 'Developer',
    5: 'Accounting',
    6: 'Read only',
}

const languages = {
    'EN': 'English',
    'ES': 'Spanish',
    'PT': 'Portuguese',
}

export {
    types,
    languages,
    TYPE_ADMIN,
    TYPE_DEV,
    TYPE_ACCOUNTING,
    TYPE_RO,
    ES,
    EN,
    PT
}