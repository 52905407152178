import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyValue from '../../TableUI/CurrencyValue'

export default function WithdrawalsCard({
    method,
    currency
}) {
    const { t } = useTranslation()
    return (
        <Card>
            <CardActionArea>
                <CardMedia
                    component="img"
                    image={`https://cdn.paygol.com/images/methods/method_button_${String(method.api_code).toLowerCase()}.png?v=7`}
                    alt={method.name}
                    sx={{
                        m: '10px',
                        width: '30%'
                    }}
                />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography gutterBottom variant="subtitle2" component="div">
                                {t('Estimated sales')}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" component="div">
                                <CurrencyValue amount={method.total > 0 ? method.total : 0} currency={currency} />
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: 'right'}}>
                            <Typography gutterBottom variant="subtitle2" component="div">
                                {t('Withdrawable')}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" component="div">
                                <CurrencyValue amount={method.available > 0 ? method.available : 0} currency={currency} />
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
