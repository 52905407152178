import { Box, Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from 'react-i18next';

import ButtonWrapper from "../../FormUI/ButtonWrapper/ButtonWrapper";
import SubmitWrapper from "../../FormUI/SubmitWrapper/SubmitWrapper";

import TextfieldWrapper from "../../FormUI/Textfield/TextfieldWrapper";
import SearchIcon from '@mui/icons-material/Search';
import UndoIcon from '@mui/icons-material/Undo';

export default function Filters({
    handleOnSearch
}) {
    const { t } = useTranslation();

    const initialFormState = {
        client_email__icontains: '',
        title__icontains: '',
        client__icontains: ''
    }

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="client_email__icontains"
                            label={t('Email')}
                            type="email"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="title__icontains"
                            label={t('Title')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={10}>
                        <TextfieldWrapper
                            name="client__icontains"
                            label={t('Client name')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
