import { useRef, useState } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { Formik, Form, FieldArray } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import DateTimePickerWrapper from '../../FormUI/DateTimePickerWrapper/DateTimePickerWrapper'
import PaymentLinksItem from './PaymentLinksItem'
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper'
import { currencies } from '../../../enums/PaymentLinks'
import { getFormatedDate } from '../../../services/utils'


export default function PaymentLinksForm({
    isNew,
    handleSubmit,
    initialFormState,
    FORM_VALIDATION
}) {
    const { t } = useTranslation()

    const ref = useRef(null)

    const today = new Date()

    const tomorrow = new Date((new Date()).setDate(today.getDate() + 1))
    
    const max_date = new Date((new Date()).setDate(today.getDate() + 60))

    const [total, setTotal] = useState(0)

    const navigate = useNavigate()

    return (
        <Formik
            innerRef={ref}
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            {({ values }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>{t('Payment links')}</Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="title"
                                label={t('Title')}
                                type="text"
                            />
                        </Grid>

                        {/* <Grid item xs={6}>
                            <TextfieldWrapper
                                name="subject"
                                label={t('Subject')}
                                type="text"
                            />
                        </Grid> */}

                        <Box width="100%" />

                        <Grid item xs={12} md={8}>
                            <TextfieldWrapper
                                name="customer.email"
                                label={t('Email address')}
                                type="email"
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="customer.first_name"
                                label={t('First name')}
                                type="text"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="customer.last_name"
                                label={t('Last name')}
                                type="text"
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={2}>
                            <SelectWrapper
                                name="currency"
                                label={t('Currency')}
                                options={currencies}
                                translate={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <DateTimePickerWrapper
                                name="due_date"
                                label={t('Due date')}
                                inputProps={{
                                    min: getFormatedDate(tomorrow),
                                    max: getFormatedDate(max_date)
                                }}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12}>
                            <TextfieldWrapper
                                name="details"
                                multiline
                                rows={2}
                                label={t('Details')}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant='h5'>{t('Items')}</Typography>
                                    </Grid>
                                </Grid>

                                <FieldArray
                                    name="items">
                                    {items => {
                                        return values.items.map((item, idx) => {
                                            return <PaymentLinksItem key={idx} idx={idx} items={items} setTotal={setTotal} />
                                        })
                                    }}
                                </FieldArray>
                            </Grid>

                            <Box width="100%" />

                            <Grid item xs={12} md={11} mt={2}>
                                <Stack direction="row" mt={1} justifyContent="flex-end">
                                    <Typography variant='h6'>{t('Total')}: {new Intl.NumberFormat('es-CL', { style: 'currency', currency: values.currency }).format(total)}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <SubmitWrapper>{t('Save')}</SubmitWrapper>
                                <ButtonWrapper
                                    variant="text"
                                    onClick={() => navigate('/payment-links')}
                                    startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>

    )
}
