import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Pagination from '../../Pagination/Pagination';
import TableActions from '../../TableUI/TableActions';

import ReadMoreIcon from '@mui/icons-material/ReadMore';

import { useNavigate } from 'react-router-dom';
import TableBackdrop from '../../TableUI/TableBackdrop';
import CurrencyValue from '../../TableUI/CurrencyValue';
import DatetimeValue from '../../TableUI/DatetimeValue';


export default function TransactionsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange,
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const handleNavigate = (view) => {
        navigate(view)
    }

    return (
        <>
            <Paper sx={{
                width: {
                    xs: 'calc(100vw - 80px)',
                    sm: 'calc(100vw - 330px)'
                }, overflow: 'hidden'
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Transaction ID')}</TableCell>
                                <TableCell align="right">{t('Country')}</TableCell>
                                <TableCell align="right">{t('Price')}</TableCell>
                                <TableCell align="right">{t('Currency')}</TableCell>
                                <TableCell align="right">{t('Method')}</TableCell>
                                <TableCell align="center">{t('Created at')}</TableCell>
                                <TableCell align="right">{t('Status')}</TableCell>
                                <TableCell align="center">{t('Status date')}</TableCell>
                                <TableCell align="center">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length && !loading ? data.map((row) => (
                                <TableRow
                                    key={row.transaction_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.transaction_id}
                                    </TableCell>
                                    <TableCell align="right">{row.country}</TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue amount={row.price} currency={row.currency} />
                                    </TableCell>
                                    <TableCell align="right">{row.currency}</TableCell>
                                    <TableCell align="right">{row.payment_method?.name}</TableCell>
                                    <TableCell align="center">
                                        <DatetimeValue date={row.created_at} />
                                    </TableCell>
                                    <TableCell align="right">{t(row.status_)}</TableCell>
                                    <TableCell align="center">
                                        <DatetimeValue date={row.status_date} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableActions actions={[
                                            {
                                                id: `details-${row.id}`,
                                                handleClick: () => { handleNavigate(`details/${row.id}`) },
                                                icon: <ReadMoreIcon />,
                                                title: t('Details')
                                            }
                                        ]} />
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                }
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
